import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import { PostHogProvider } from "posthog-js/react";

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider apiKey="phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH">
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

