import { useLottie } from "lottie-react";
import logo from "../assets/logo.svg";
import homeAnimation from "../assets/home-animation.json";
import { useState } from "react";
import "./index.css";
import { Toaster, toast } from "sonner";
import { useLocalStorage, useWindowSize } from "usehooks-ts";
import Confetti from "react-confetti";

export function App() {
  const { width, height } = useWindowSize();
  const [email, setEmail] = useState("");
  const [hasRequestedAccess, setHasRequestedAccess] = useLocalStorage(
    "hasRequestedAccess",
    "false"
  );

  const options = {
    animationData: homeAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  const requestAccess = async (e) => {
    const hubspotPortalId = "40056667";
    const hubspotFormId = "1d685dd4-1ad6-45fe-90a4-b7f311f178af";
    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormId}`;

    const payload = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {},
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok) {
        setHasRequestedAccess(true);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error requesting access, try again.");
    }
  };

  return (
    <div className="app">
      {hasRequestedAccess !== "false" && (
        <Confetti width={width} height={height} recycle={false} />
      )}
      <div className="grunge-underlay"></div>
      <Toaster />
      <div className="login-button">
        <a href="https://app.connectbetter.io" target="_blank" rel="noopener noreferrer">
          Login
        </a>
      </div>
      <div className="app-content">
        <div className="logo-container">
          <img src={logo} className="logo" alt="logo" />
        </div>

        <h1>Finally, a better home for your community.</h1>
        <p>A place to build relationships and grow.</p>

        {hasRequestedAccess === "false" ? (
          <div className="request-access-container">
            <input
              type="email"
              placeholder="Your email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  requestAccess();
                }
              }}
            />
            <div className="button" onClick={requestAccess}>
              <div className="button-text">Join Waitlist</div>
              <div className="button-content">{View}</div>
            </div>
          </div>
        ) : (
          <div
            style={{
              minHeight: "70px",
              width: "100%",
              border: "1px solid #0000004c",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              padding: "0.5rem 1rem",
              justifyContent: "center",
            }}
          >
            <div>🎉 You've requested access! We'll email you when we launch. </div>
          </div>
        )}
        <div style={{ opacity: 0.5, width: "100%", marginTop: "2rem" }}>
          Join waitlist now to get our special intro pricing. Launching in
          September, 2024.
        </div>
      </div>
    </div>
  );
}